<!-- =========================================================================================
    File Name: EditPlacementAnchor.vue
    Description: Card component to see the name of the object and button to edit and delete it.
========================================================================================== -->

<template>
  <div>
    <vx-tooltip :key="refreshHackIndex" :enable="hobject.name.length > 48" delay=".5s" :text="hobject.name">
      <div v-if="mainNode" @click="onNodeCardClick($event, mainNode)">
        <vs-list-item 
        :subtitle ="getHobjectSubtitleFromModelIdentifier(hobject.model_identifier)"
        :title="hobject.name.length > 48? hobject.name.substring(0, 47) + '...' : hobject.name"
        :class="{ 'selected-item': isSelected(mainNode) }">
            <template slot="avatar">
            <vs-avatar :src="hobject.image"/>
            </template>
          <template>
            <div class="vx-col w-full flex">
              <feather-icon
                v-if="getAnchor(mainNode) && (getAnchor(mainNode).anchor_type == 'image' || getAnchor(mainNode).anchor_type == 'vuforia-instant-image-target')"
                v-on:click="parent.editAnchor(mainNode.anchor_pid)"
                icon="AnchorIcon"
                svgClasses="w-6 h-6"
              ></feather-icon>
              <feather-icon
                v-on:click.stop="setEditPanelVisible(mainNode)" 
                class="ml-5" 
                :icon="mainNode.isEditPanelVisible ? 'ChevronUpIcon' : 'ChevronDownIcon'" 
                svgClasses="w-5 h-5">
              </feather-icon>
              <feather-icon 
                v-on:click.stop="parent.editHobject(mainNode.hobject_pid)" 
                class="ml-2"
                icon="Edit2Icon" 
                svgClasses="w-4 h-4">
              </feather-icon>
              <feather-icon 
                v-on:click.stop="parent.confirmRemoveNode(mainNode.pid)" 
                class="ml-2" 
                icon="TrashIcon" 
                svgClasses="w-4 h-4">
              </feather-icon>
            </div>  
        </template>
        </vs-list-item>
        <div v-if="isEditPanelVisible(mainNode)" 
        @click.stop
        :class="['p-2', { 'selected-item': isSelected(mainNode) }]">
          <div class="vx-row">
            <div class="vx-col w-1/3">
              <v-unit-input
                    type="number"
                    :v-validate="'decimal'"
                    :value="mainNode.x"
                    :unit-system="unitSystem"
                    label="position X"
                    name="decimal"
                    size="small"
                    css-class="w-10/12"
                    @input="value => updateMainNode(mainNode, 'x', value)"
                  />
                 <!-- <vs-input
                type="number"
                v-validate="'decimal'"
                label="Left/Right (x)"
                name="decimal"
                size="small"
                v-on:change="onInput(mainNode)"
                v-model.number="mainNode.x"
                class="w-full"
              /> -->
            </div>
            <div class="vx-col w-1/3">
              <v-unit-input
                type="number"
                :v-validate="'decimal'"
                :value="mainNode.y"
                :unit-system="unitSystem"
                label="position Y"
                name="decimal"
                size="small"
                css-class="w-full"
                @input="value => updateMainNode(mainNode, 'y', value)"
              />
            </div>
            <div class="vx-col w-1/3">
              <v-unit-input
                type="number"
                :v-validate="'decimal'"
                :value="mainNode.z"
                :unit-system="unitSystem"
                label="position Z"
                name="decimal"
                size="small"
                css-class="w-full"
                @input="value => updateMainNode(mainNode, 'z', value)"
              />
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-1/3">
                <vs-input
                v-validate="'decimal'"
                type="number"
                label="Rotation X"
                size="small"
                v-on:change="onInput(mainNode)"
                v-model.number="mainNode.angle_x"
                class=" w-full"
              />
            </div>
            <div class="vx-col w-1/3">
              <vs-input
                v-validate="'decimal'"
                type="number"
                label="Rotation Y"
                size="small"
                v-on:change="onInput(mainNode)"
                v-model.number="mainNode.angle_y"
                class=" w-full"
              />
            </div>
            <div class="vx-col w-1/3">
              <vs-input
                v-validate="'decimal'"
                type="number"
                label="Rotation Z"
                size="small"
                v-on:change="onInput(mainNode)"
                v-model.number="mainNode.angle_z"
                class=" w-full"
              />
            </div>
            <div class="vx-col w-1/3">
              <vs-input
                v-validate="'decimal'"
                type="number"
                label="Scale"
                size="small"
                v-on:change="onInput(mainNode)"
                v-model.number="mainNode.scale"
                class="w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </vx-tooltip>
    <div v-if="nodesWithSameHobject">
      <div v-for="node in nodesWithSameHobject"
      class="ml-12"
      v-bind:key="node.pid" 
      v-on:click="onNodeCardClick($event, node)">
        <vs-list-item 
        :subtitle ="getHobjectSubtitleFromModelIdentifier(hobject.model_identifier)"
          :title="'[clone] ' + (hobject.name.length > 40 ? hobject.name.substring(0, 40) + '...' : hobject.name)"
          :class="{ 'selected-item': isSelected(node) }"
          :title-color="isSelected(node) ? 'white' : 'black'">
            <template>
              <div class="vx-col w-full flex">
              <feather-icon 
                v-on:click.stop="setEditPanelVisible(node)" 
                class="ml-5" 
                :icon="node.isEditPanelVisible ? 'ChevronUpIcon' : 'ChevronDownIcon'" 
                svgClasses="w-4 h-4">
              </feather-icon>
              <feather-icon 
                v-on:click.stop="parent.editHobject(node.hobject_pid)" 
                class="ml-1" 
                icon="Edit2Icon" 
                svgClasses="w-4 h-4">
              </feather-icon>
              <feather-icon 
                v-on:click.stop="parent.confirmRemoveNode(node.pid)" 
                class="ml-1" 
                icon="TrashIcon" 
                svgClasses="w-4 h-4">
              </feather-icon>
              </div>  
          </template>
        </vs-list-item>
        <div v-if="isEditPanelVisible(node)"
        @click.stop
        :class="['p-2', { 'selected-item': isSelected(node) }]">
          <div class="vx-row">
            <div class="vx-col w-1/3">
              <v-unit-input
                type="number"
                :v-validate="'decimal'"
                :value="node.x"
                :unit-system="unitSystem"
                label="position X"
                name="decimal"
                size="small"
                css-class="w-full"
                @input="value => updateMainNode(node, 'x', value)"
              />
            </div>
            <div class="vx-col w-1/3">
              <v-unit-input
                type="number"
                :v-validate="'decimal'"
                :value="node.y"
                :unit-system="unitSystem"
                label="position Y"
                name="decimal"
                size="small"
                css-class="w-full"
                @input="value => updateMainNode(node, 'y', value)"
              />
            </div>
            <div class="vx-col w-1/3">
              <v-unit-input
                type="number"
                :v-validate="'decimal'"
                :value="node.z"
                :unit-system="unitSystem"
                label="position Z"
                name="decimal"
                size="small"
                css-class="w-full"
                @input="value => updateMainNode(node, 'z', value)"
              />
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-1/3">
              <vs-input
                label="Rotation X"
                type="number"
                size="small"
                v-on:change="onInput(node)"
                v-model.number="node.angle_x"
                class=" w-full"
              />
            </div>
            <div class="vx-col w-1/3">
              <vs-input
                label="Rotation Y"
                type="number"
                size="small"
                v-on:change="onInput(node)"
                v-model.number="node.angle_y"
                class=" w-full"
              />
            </div>
            <div class="vx-col w-1/3">
              <vs-input
                label="Rotation Z"
                type="number"
                size="small"
                v-on:change="onInput(node)"
                v-model.number="node.angle_z"
                class=" w-full"
              />
            </div>
            <div class="vx-col w-1/3">
              <vs-input
                type="number"
                label="Scale"
                size="small"
                v-on:change="onInput(node)"
                v-model.number="node.scale"
                class="w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>  
</template>  
<script>
import 'flatpickr/dist/flatpickr.css'
import * as Command from '@/assets/js/utils/commands.js'
import * as HoverlayThreeUtils from '@/assets/js/utils/hoverlay-three-utils.js'
import VUnitInput from '@/views/hoverlay/components/VUnitInput.vue';

export default {
  components: {
    VUnitInput,
  },
  props: {
    nodes: {},
    hobject: {},
    hobjects: {},
    anchors: {},
    selectedNodes: [],
    mainNode: {}, 
    nodesWithSameHobject: {},
    // store old node value
    oldNodeValue: null,
  },
  data() {
    return {
      parent: this.$parent,
      backgroundColor: null,
      refreshHackIndex: 0,
      selectedNodeCards: [],
      unitSystem: this.$store.state.AppActiveUser.measurement, // or 'metric'
    }
  },
  watch: {
    mainNode: {
      // This will let Vue know to look inside the object
      deep: true,
      // We have to move our method to a handler field
      handler() {},
    },
    nodes: function() {},
    selectedNodesCopy(newNodes, oldNodes) {
      // Update the selected objects in the list
      this.updateSelectedNodes(newNodes);
    },
  },
  computed: {
    selectedNodesCopy() {
      return [...this.selectedNodes];
    },
    filteredNodesWithSameHobject() {
      return this.nodesWithSameHobject.filter(node => node.pid !== this.mainNode.pid);
    }
  },
  async mounted() {
    // var element = document.getElementsByClassName('edit-placement-anchor-card')[0]
    // if (element) element.style.height = this.nodesWithSameHobject.length * 70 + 'px'
  },
  created() {},
  methods: {
    updateMainNode(node, attribute, value) {
      node[attribute] = value
      this.updateNodeTransform(node);
    },
    onInput(new_node) {
      console.log('onInput')
          console.log (new_node)
      this.$nextTick(() => {
        if (
          this.isNumeric(new_node.x) &&
          this.isNumeric(new_node.y) &&
          this.isNumeric(new_node.z) &&
          this.isNumeric(new_node.angle_x) &&
          this.isNumeric(new_node.angle_y) &&
          this.isNumeric(new_node.angle_z) &&
          this.isNumeric(new_node.scale)
        ) {

          this.updateNodeTransform(new_node);
          // this.$eventBus.$emit('nodeTransformChanged', new_node)
        }
      })
    },
    updateNodeTransform(new_node) {
          console.log('onInput true')
          var n = this.nodes.find(node => node.pid === new_node.pid)
          // Set quaternion value on new_node based on angle_x, angle_y, angle_z
          const quaternion =  HoverlayThreeUtils.eulerToQuaternion(new_node.angle_x, new_node.angle_y, new_node.angle_z)
          new_node.quaternion_x = quaternion.x
          new_node.quaternion_y = quaternion.y
          new_node.quaternion_z = quaternion.z
          new_node.quaternion_w = quaternion.w
          var command = new Command.TransformNodeToCommand(n, new_node)
          this.$emit('updateNodeTransform', command)    },
    getAnchor(node) {
      return this.anchors.find(anchor => anchor.pid == node.anchor_pid)
    },
    getHobjectNameFromNode(node) {
      var name = this.hobjects.find(hobject => hobject.pid == node.hobject_pid).name
      return name != null ? name : 'Unamed object'
    },
    getHobjectSubtitleFromModelIdentifier(model_identifier) {
      switch (model_identifier) {
        case 'hoverlay.core.Audio.1.0':
          return this.$t('HoverpackSelectionAudioSourceTitle')
        case 'hoverlay.core.Video.1.0':
          return this.$t('HoverpackSelectionVideoTitle')
        case 'hoverlay.core.ChromaVideo.1.0':
          return this.$t('HoverpackSelectionHologramTitle')
        case 'hoverlay.core.Image.1.0':
          return this.$t('HoverpackSelectionImageTitle')
        case 'hoverlay.core.Button.1.0':
          return this.$t('HoverpackSelectionCTATitle')
        case 'hoverlay.core.3dModel.1.0':
          return this.$t('HoverpackSelection3DTitle')
        case 'hoverlay.core.TextCard.1.0':
          return this.$t('HoverpackSelectionTextTitle')
        case 'hoverlay.core.Portal.1.0':
          return this.$t('HoverpackSelectionPortalTitle')
        case 'hoverlay.core.UnityAssetBundle.1.0':
          return this.$t('HoverpackSelectionAssetBundlesTitle')
        default:
          return ''
      }
    },
    setEditPanelVisible(node) {
      if (node.isEditPanelVisible == true) {
        node.isEditPanelVisible = false
      } else {
        node.isEditPanelVisible = true
      }
      this.refreshHackIndex++
    },
    isEditPanelVisible(node) {
      if (node == null) return false
      if (node.isEditPanelVisible == true) return true
      else false
    },
    onNodeCardClick(event, node) {
      console.log('onNodeCardClick')
      if (!event.shiftKey) {
        // If card is the only one selected, deselect it
        if (this.selectedNodeCards.length == 1 && this.isSelected(node)) {
          this.$emit('removeNodeFromSelection', node)
          return
        }
        if (!this.nothingSelected()) { // Deselect all nodes if multiple nodes are selected
          for (const n of this.selectedNodeCards) {
              this.$emit('removeNodeFromSelection', n)
          }
        }
        this.$emit('addNodeToSelection', node)
      }
      else {
        // If shift key is pressed, toggle the node clicked
        if (this.isSelected(node)) {
          this.$emit('removeNodeFromSelection', node)
        } else {
          this.$emit('addNodeToSelection', node)
        }
      }
    },

    // If some nodes are in selectedNodeCards list but not in nodes, remove them from the selectedNodeCards list.
    // If they are not, add them to the selectedNodeCards list. 
    updateSelectedNodes(nodes) {
      if (this.selectedNodeCards.length != 0) {
        for (const node of this.selectedNodeCards) {
          if (!nodes.find(n => n.pid == node.pid)) {
            this.onRemovedFromSelection(node)
          }
        }
      }
      for (const node of nodes) {
        if (!this.isSelected(node)) {
          this.onAddedToSelection(node)
        }
      }
    },
    isSelected(node) {
      if (node == null || this.selectedNodeCards.length < 1) return false
      return this.selectedNodeCards.find(n => n.pid == node.pid)
    },
    nothingSelected() {
      return this.selectedNodeCards.length == 0
    },
    onAddedToSelection(node) {
      if (node == null) return
      this.selectedNodeCards.push(node)
    },
    onRemovedFromSelection(node)  {
      if (node == null) return
      // Remove node from selectedNodeCards list
      this.selectedNodeCards = this.selectedNodeCards.filter(n => n.pid != node.pid)
    },
    isNumeric(n) {
      return (Number(n) === n && n % 1 === 0) || (Number(n) === n && n % 1 !== 0)
    },
  },
}
</script>

<style scoped>
.selected-item {
  background-color: rgba(var(--vs-primary),.15);
}

</style>
