<!-- =========================================================================================
    Description: Page to edit a given space
========================================================================================== -->

<template>
  <div class="vx-row">
    <div class="vx-col w-full sm:w-1/4 lg:w-2/12">
      <div class="vx-row"></div>
      <div id="first-column" class="vx-row">
        <vs-button
          icon-pack="feather"
          icon="icon-settings"
          size="small"
          @click="EditSpace()"
          class="w-5/12 mr-5"
          type="border"
          color="primary"
          >{{ this.$t('SpaceSettings') }}</vs-button
        >
        <vs-button
          icon-pack="feather"
          icon="icon-check"
          :disabled="isSaveButtonDisabled"
          size="small"
          @click="updateSpaceAndNodes()"
          class="w-6/12"
          :type="getButtonSaveType()"
          >{{ this.$t('SaveButton') }}</vs-button
        >

        <vx-card class="mt-3">
          <center>
            <span>{{ this.$t('QrCode') }}</span>
          </center>
          <div class="mt-2 flex">
            <div class="m-auto">
              <div class="qr-code align-middle text-center">
                <vue-qr
                  height="60px"
                  width="90%"
                  :margin="40"
                  :logoSrc="hoverlayIconSrc"
                  :dotScale="0.7"
                  colorDark="#444444"
                  :size="1024"
                  :callback="getData64fromQrCode"
                  :text="qrCodeUrl"
                  :logoMargin="16"
                  :correctLevel="3"
                ></vue-qr>
              </div>
              <div class="align-middle text-center">
                <vs-button size="small" color="dark" type="flat">
                  <feather-icon size="small" icon="DownloadIcon" v-on:click="onQrCodeDownload" />
                </vs-button>
                <vs-button
                  size="small"
                  v-clipboard:copy="qrCodeUrl"
                  color="dark"
                  v-clipboard:success="onQrCodeCopy"
                  type="flat"
                >
                  <feather-icon size="small" icon="LinkIcon" />
                </vs-button>
              </div>
            </div>
          </div>
        </vx-card>
        <vx-card class="mt-4">
          <center>
            <span>Download Score</span>
            <vue-gauge
              :refid="'type-unique-id'"
              :options="{
                rangeLabelFontSize: '10',
                needleValue: this.performanceScore * 10,
                chartWidth: '200',
                hasNeedle: true,
                needleColor: 'black',
                needleStartValue: 0,
                arcColors: ['rgb(61,204,91)', 'rgb(239,214,19)', 'rgb(255,84,84)'],
                arcDelimiters: [33, 66],
                rangeLabel: ['Light', 'Heavy'],
                centralLabel: `${this.performanceScoreText}`,
              }"
            ></vue-gauge>
            <span>
              <div v-if="this.performanceScore < 3.3">
                {{ this.$t('SizeContent') }} {{ totalContentLengthHumanReadable }}. Awesome, this space is well
                optimized for every mobile user.
              </div>
              <div v-else-if="this.performanceScore <= 6.6 && this.performanceScore >= 3.3">
                {{ this.$t('SizeContent') }} {{ totalContentLengthHumanReadable }}. Consider compressing your assest for
                better user experience.
              </div>
              <div v-else>
                {{ this.$t('SizeContent') }} {{ totalContentLengthHumanReadable }}. Compress your assets to avoid long
                download time and poor rendering on low-end devices.
                <a href="https://docs.spaces.hoverlay.com/1.0/content-creation-guidelines">Learn More </a>
              </div>
            </span>
          </center>
        </vx-card>

        <!-- <div class="vx-col w-full">
          <vs-button @click="EditSequencing()" class="w-full mr-3 mb-4" type="border" color="primary">Edit Sequencing</vs-button>
        </div> -->
      </div>

      <!-- let chartWidth = 150
let needleValue = 22
let options = {
	hasNeedle: true,
	needleColor: "black",
	needleStartValue: 50,
	arcColors: ["rgb(255,84,84)","rgb(239,214,19)","rgb(61,204,91)"],
	arcDelimiters: [40,60],
	rangeLabel: ["52","8"],
} -->
    </div>
    <div class="vx-col w-full sm:w-1/2 lg:w-7/12 mb-base mb-2">
      <div class="mb-4" v-if="this.trianglesCount > 300000 || videoObjectCount > 3 || hobjects.length > 12">
        <vs-alert
          icon-pack="feather"
          icon="icon-info"
          color="danger"
          title="Mobile device performance warning"
          active="true"
        >
          <p v-if="this.trianglesCount > 300000">
            &bull; Total triangle count <b>({{ trianglesCount }})</b> exceeds our recommandations for entry level
            devices <b>(max 300 000).</b>
            <a href="https://docs.spaces.hoverlay.com/1.0/content-creation-guidelines/3d-models" target="_blank"
              >[How to optimize?]</a
            >
            on you can further optimize your space.
          </p>
          <p v-if="videoObjectCount > 3">
            &bull; Your space exceeds the recommended number of videos <b>({{ videoObjectCount }})</b> for entry level
            devices. Suggestions for optimizing performances :
            <a
              href="https://docs.spaces.hoverlay.com/1.0/content-creation-guidelines/videos#resolution-and-compression"
              target="_blank"
              >click here</a
            >
          </p>
        </vs-alert>
      </div>

      <div v-if="anchors_spaces.length > 1" class="ml-1 vx-row w-3/4">
        <div
          @click="OnReferenceImageTabClicked(anchor_space.anchor)"
          v-for="anchor_space in anchors_spaces"
          v-bind:key="anchor_space.anchor.pid"
          class="tab mr-1"
          v-bind:class="{ 'unselected-tab': selectedAnchor && anchor_space.anchor.pid != selectedAnchor.pid }"
        >
          <div
            v-if="selectedAnchor && anchor_space.anchor.pid == selectedAnchor.pid"
            @click="confirmRemoveAnchor(anchor_space.anchor)"
            class="close-thik"
          >
            <feather-icon icon="TrashIcon" svgClasses="h-3 w-3 stroke-current" class="block" />
          </div>
          <img
            :alt="anchor_space.anchor.pid"
            :src="getThumbnailFromAnchorPid(anchor_space.anchor)"
            class="center-cropped"
          />
        </div>
        <!-- <div style="background-color: transparent;" @click="create(floorAnchor)" class="tab mr-1 add-object-tab">
          <img :src="require('@/assets/images/hoverlay/plus-icon.png')" class="center-cropped" />
        </div> -->
      </div>
      <vx-card v-if="selectedAnchor && selectedAnchor.pid == this.anchorPidFloor" class="card-no-padding">
        <floor-3d-scene
          ref="floor3dScene"
          v-bind:space="space"
          v-bind:nodes="userRelativeNodes"
          @addNodeToSelection="addNodeToSelection"
          @removeNodeFromSelection="removeNodeFromSelection"
          @updateNodeTransform="updateNodeTransform"
          v-bind:selectedNodes="selectedNodes"
          v-bind:anchors="anchors"
        ></floor-3d-scene>
      </vx-card>

      <div v-else-if="anchors_spaces.length > 0">
        <div v-for="anchor_space in anchors_spaces" v-bind:key="anchor_space.anchor.pid">
          <reference-image-3d-scene
            v-if="selectedAnchor && anchor_space.anchor.pid == selectedAnchor.pid"
            v-bind:imageAnchor="selectedAnchor"
            v-bind:nodes="getNodesFromAnchor(selectedAnchor)"
            @addNodeToSelection="addNodeToSelection"
            @removeNodeFromSelection="removeNodeFromSelection"
            @updateNodeTransform="updateNodeTransform"
            v-bind:selectedNodes="selectedNodes"
            v-bind:hobjects="hobjects"
          >
          </reference-image-3d-scene>
        </div>
      </div>
      <div v-else></div>

      <!-- <vs-row vs-type="flex" vs-justify="center">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
            <vs-radio v-model="gridSize" vs-value="40"><b>x1</b></vs-radio>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
            <vs-radio v-model="gridSize" vs-value="20"><b>x0.5</b></vs-radio>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
            <vs-radio v-model="gridSize" vs-value="10"><b>x0.25</b></vs-radio>
          </vs-col>
        </vs-row>  -->

      <br />

      <!-- Download indicator -->
      <div v-if="totalContentLength > 0">
        <vs-alert
          icon-pack="feather"
          icon="icon-info"
          :color="this.getDownloadColorIndicator(this.totalContentLength)"
          :title="this.$t('DownloadTitle')"
          active="true"
        >
          <p>
            <!-- {{ this.$t('SizeContent') }}
            {{ totalContentLengthHumanReadable }}
            {{ '\xa0' + '\xa0' + '-' + '\xa0' }} -->
            {{ this.$t('DownloadWifi') }}
            : {{ downloadTimeWifi + '\xa0' + '\xa0' + '-' + '\xa0' }} {{ this.$t('Download4G') }} :
            {{ downloadTime4G + '\xa0' + '\xa0' + '-' + '\xa0' }} {{ this.$t('Download3G') }} :
            {{ downloadTime3G }}
            <!-- {{ '\xa0' + '\xa0' + '-' + '\xa0' this.$t('Download2G') }} : {{ downloadTime2G }} -->
          </p>
        </vs-alert>
        <br />
      </div>
    </div>
    <div class="vx-col w-3/12">
      <div class="vx-row">
        <div class="vx-col w-full mb-1">
            <vs-button
              icon-pack="feather"
              icon="icon-play"
              size="medium"
              @click="isSequencingEnable ? goToSequencesPage() : showUpgradeMessage()"
              class="w-full mt-1 mb-2"
              type="border"
              >Sequences</vs-button
            >
        </div>
        <div class="vx-col w-7/12 mb-1">
          <vs-button
            icon-pack="feather"
            icon="icon-plus"
            size="medium"
            @click="createNode(selectedAnchor.pid)"
            class="w-full mt-1"
            type="border"
            >Add Object</vs-button
          >
        </div>
        <div class="vx-col w-5/12">
          <vs-button
            icon-pack="feather"
            icon="icon-plus"
            size="small"
            @click="create(floorAnchor)"
            class="w-full mt-1"
            type="border"
            color="primary"
            >Add Anchor</vs-button
          >
        </div>

        <div class="vx-col w-full mb-2">
          <vs-alert
            icon-pack="feather"
            icon="icon-star"
            v-if="userRelativeNodes.length == 0 && imageAnchors.length == 0"
            active="true"
          >
            Click on "Add Object" to attach a new object on this anchor !
          </vs-alert>
        </div>
      </div>
      <div v-if="selectedAnchor">
        <edit-placement-anchor-card
          v-for="hobject in removeDuplicates(hobjects)"
          v-bind:selectedNodes="selectedNodes"
          v-bind:nodesWithSameHobject="getCloneNodesFromHobject(hobject)"
          v-bind:mainNode="getNodesOrderByLastUpdated(hobject)[0]"
          v-bind:space="space"
          v-bind:hobject="hobject"
          v-bind:nodes="nodes"
          v-bind:anchors="anchors"
          v-bind:key="hobject.pid"
          @addNodeToSelection="addNodeToSelection"
          @removeNodeFromSelection="removeNodeFromSelection"
          @updateNodeTransform="updateNodeTransform"
        >
        </edit-placement-anchor-card>
      </div>
      <!-- {{ filteredNodes }} -->
    </div>
    <!-- {{ imageRelativeNodes }} -->
  </div>
</template>

<script>
// Import tools to crop the hologram
import '@/assets/js/utils/greenscreen-video-tools.js'
import * as Utils from '@/assets/js/utils/utils.js'
import * as SequencesUtils from '@/assets/js/utils/sequences-utils.js'
import { CommandStack } from '@/assets/js/utils/command-stack.js'
import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'
import 'video.js/dist/video-js.css'
import humanizeDuration from 'humanize-duration'
import EditPlacementAnchorCard from '@/views/hoverlay/pages/edit-space/components/EditPlacementAnchorCard.vue'
import Floor3dScene from '@/views/hoverlay/pages/edit-space/components/Floor3dScene.vue'
import ReferenceImage3dScene from '@/views/hoverlay/pages/edit-space/components/ReferenceImage3dScene.vue'
import VueGauge from 'vue-gauge'
import vSelect from 'vue-select'
export default {
  props: { space: {}, hobjects: {}, nodes: {}, anchors: {}, models: {}, anchorPidFloor: {}, anchors_spaces: {} },
  components: {
    // 'v-select': vSelect,
    Floor3dScene,
    EditPlacementAnchorCard,
    // eslint-disable-next-line vue/no-unused-components
    ReferenceImage3dScene,
    VueGauge,
  },
  data() {
    return {
      layer_name: JSON.parse(localStorage.getItem('layerInfo')).name,
      selectedNodes: [],
      showAdvanced: true,
      pov: null,
      pano: null,
      qrCodeUrl: 'empty',
      qrCodeData64: null,
      hoverlayIconSrc: require('@/assets/images/hoverlay/logo.png'),
      range: null,
      description: null,
      present_from: new Date(),
      present_to: new Date(),
      // gauge :
      needleValue: 22,
      options: {
        chartWidth: '200',
        hasNeedle: true,
        needleColor: 'black',
        needleStartValue: this.performanceScore,
        arcColors: ['rgb(239,214,19)', 'rgb(61,204,91)', 'rgb(255,84,84)'],
        arcDelimiters: [33, 66],
        rangeLabel: ['0', '100'],
        centralLabel: `${this.performanceScore}/100`,
      },
      // Anchor selection
      floorAnchor: null,
      anchorOptions: null,
      selectedAnchor: null,
      isSaveButtonDisabled: null,
      commandStack: null,
      isSequencingEnable: HoverlayUtils.isSequencingEnable(),
    }
  },
  watch: {
    space: {
      deep: true,
      handler(newSpace, oldSpace) {},
    },
    nodes: {
      deep: true,
      handler(newNodes, oldNodes) {
        // If no nodes are attached to the current selected anchor there is no need for the anchor to be selected anymore
        if (this.selectedAnchor) {
          var nodeAttachedToSelectedAnchor = newNodes.find(newNode => newNode.anchor_pid == this.selectedAnchor.pid)
          if (nodeAttachedToSelectedAnchor == undefined) this.selectedAnchor = this.anchors_spaces[0].anchor
        }
        this.checkIfNodesAreDirties()
      },
    },
  },
  computed: {
    trianglesCount: function() {
      if (this.nodes.length > 0) {
        var dic = {}
        this.nodes.forEach(node => {
          var h = this.getHobject(node.hobject_pid)
          dic[node.pid] = h.triangles_count
        })
        return Object.values(dic).reduce((a, b) => a + b)
      } else return 0
    },
    videoObjectCount() {
      var videoObjectCount = 0
      this.hobjects.forEach(h => {
        if (h.model_identifier == 'hoverlay.core.Video.1.0' || h.model_identifier == 'hoverlay.core.ChromaVideo.1.0')
          videoObjectCount++
      })
      return videoObjectCount
    },
    performanceScoreText: function() {
      if (this.performanceScore > 6.6) return 'Heavy'
      else if (this.performanceScore <= 6.6 && this.performanceScore >= 3.3) return 'Medium'
      else return 'Light'
    },
    performanceScore: function() {
      return this.getPerformanceScore(this.totalContentLength)
    },
    downloadTime2G: function() {
      return this.computeTimeHumanReadable(0.1)
    },
    downloadTime3G: function() {
      return this.computeTimeHumanReadable(0.3)
    },
    downloadTime4G: function() {
      return this.computeTimeHumanReadable(2)
    },
    downloadTimeWifi: function() {
      return this.computeTimeHumanReadable(10)
    },
    totalContentLength: function() {
      if (this.nodes.length > 0) {
        var dic = {}
        this.nodes.forEach(node => {
          var h = this.getHobject(node.hobject_pid)
          dic[h.pid] = h.content_length
        })
        return Object.values(dic).reduce((a, b) => a + b)
      } else return 0
    },
    totalContentLengthHumanReadable: function() {
      return Utils.humanFileSize(this.totalContentLength, true)
    },
    imageAnchors: function() {
      return this.anchors.filter(
        anchor => anchor.anchor_type == 'image' || anchor.anchor_type == 'vuforia-instant-image-target'
      )
    },
    space_pid: function() {
      return this.$route.params.pid
    },
    latitude: function() {
      return this.spaces.find(placement => placement.pid == this.space_pid).latitude
    },
    longitude: function() {
      return this.spaces.find(placement => placement.pid == this.space_pid).longitude
    },
    userRelativeNodes() {
      // Only HORIZONTAL_SURFACE TABLE-TOP SCENE_ORIGIN will show up on the map
      return this.nodes.filter(
        node =>
          node.anchor_pid == this.anchorPidFloor ||
          node.anchor_pid == this.anchorPidSceneOrigin ||
          node.anchor_pid == this.anchorPidTableTop
      )
    },
    imageRelativeNodes() {
      var l = []

      this.nodes.forEach(node => {
        if (this.anchors.find(anchor => anchor.anchor_type == 'image' && node.anchor_pid === anchor.pid)) l.push(node)
      })
      // console.log('imageRelativeNodes()')
      // console.log(this.nodes)
      return l
    },
    compareDate(a, b) {
      if (a.updated_at_timestamp < b.updated_at_timestamp) {
        return 1
      }
      if (a.updated_at_timestamp > b.updated_at_timestamp) {
        return -1
      }
      return 0
    },
  },
  mounted() {
    this.init()
    this.commandStack = new CommandStack()
    this.getTotalSizeExperience()
    this.checkIfNodesAreDirties()
    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('keyup', this.onKeyUp)
  },
  beforeDestroy() {
    this.selectedNodes = []
    this.showAdvanced = true
    this.pov = null
    this.pano = null
    this.qrCodeUrl = 'empty'
    this.range = null
    this.description = null
    this.present_from = null
    this.present_to = null
    this.anchor_type = null
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('keyup', this.onKeyUp)
  },
  created() {
    var anchor_space = this.anchors_spaces.find(anchor_space => anchor_space.anchor.pid == this.$route.query.anchor)
    if (anchor_space && anchor_space.anchor) this.selectedAnchor = anchor_space.anchor
    else this.setDefaultAnchor()

  },
  methods: {
    checkIfNodesAreDirties() {
      var isSaveButtonDisabled = true
      this.nodes.forEach(node => {
        // if is_dirty is not undefined and is true, enable the save button
        if (node.is_dirty != undefined && node.is_dirty == true) {
          isSaveButtonDisabled = false
          return
        }
      })
      this.isSaveButtonDisabled = isSaveButtonDisabled
      return isSaveButtonDisabled
    },
    undo() {
      const command = this.commandStack.undo()

      // Fire event to update the node transform if the command affects a node or a group of nodes
      if (command) {
        switch (command.type) {
          case 'node':
            this.$eventBus.$emit('nodeTransformChanged', command.node)
            break
          case 'node_group':
            command.commands.forEach(c => {
              this.$eventBus.$emit('nodeTransformChanged', c.node)
            })
            break
          default:
            break
        }
      }

      // If nothing else to undo, notify the user
      if (!this.commandStack.canUndo()) {

        // // If we are at the end of the stack, and are past a point that was saved, enable the save button
        //   if (this.commandStack.priorToLastSave()) {
        //     this.isSaveButtonDisabled = false
        //   } else {
        //     // Assume the user has not saved, and we are back to the experience that was loaded from the server.
        //     this.isSaveButtonDisabled = true
        //   }

          this.$vs.notify({
          text: 'Nothing left to undo.',
          iconPack: 'feather',
          color: 'danger',
        })
      }
    },
    redo() {
      const command = this.commandStack.redo()
      if (command) {
        // Fire event to update the node transform if the command affects a node or a group of nodes
        switch (command.type) {
          case 'node':
            this.$eventBus.$emit('nodeTransformChanged', command.node)
            break
          case 'node_group':
            command.commands.forEach(c => {
              this.$eventBus.$emit('nodeTransformChanged', c.node)
            })
            break
          default:
            break
        }
      }
      if (!this.commandStack.canRedo()) {

        this.$vs.notify({
          text: 'Nothing left to redo.',
          iconPack: 'feather',
          color: 'danger',
        })
      }
    },
    onKeyDown(event) {
      if (event.shiftKey) {
        document.body.classList.add('no-select');
      }
      if (event.which === 89 && event.ctrlKey) {
        this.redo()
      } else if (event.which === 90 && event.ctrlKey) {
        this.undo()
      } else if (event.which === 89 && event.metaKey) {
        this.redo()
      } else if (event.which === 90 && event.metaKey) {
        this.undo()
      }
    },
    onKeyUp(event) {
      if (!event.shiftKey) {
        document.body.classList.remove('no-select');
      }
    },
    getButtonSaveType() {
      if (this.isSaveButtonDisabled) return 'border'
      return 'filled'
    },
    OnReferenceImageTabClicked(anchor) {
      console.log('OnReferenceImageTabClicked')
      this.selectedAnchor = null
      this.selectedAnchor = anchor
    },
    computeTimeHumanReadable(downloadSpeed) {
      if (this.totalContentLength > 0) {
        if (this.totalContentLength / (downloadSpeed * 1000 * 1000) > 1)
          return humanizeDuration((this.totalContentLength / (downloadSpeed * 1000 * 1000)) * 1000, {
            maxDecimalPoints: 0,
          })
        else return this.$t('DownloadInst')
      } else return this.$t('DownloadInst')
    },
    async getTotalSizeExperience() {
      this.totalSize = 0
      const p = [...this.userRelativeNodes, ...this.imageRelativeNodes]
      // console.log('getTotalSizeExperience')
      // console.log(p)
      p.forEach(placement => {
        this.totalSize += this.getHobject(placement.hobject_pid).content_length
        // console.log('getTotalSizeExperience')
        // console.log(this.getHobject(placement.hobject_pid))
      })
      this.humanFileSize = Utils.humanFileSize(this.totalSize)
    },

    getAnchorName(anchor_pid) {
      return this.anchors.filter(anchor => anchor.pid == anchor_pid)[0].name
    },
    async cloneNode(node) {
      var clones = this.nodes.filter(n => n.hobject_pid == node.hobject_pid)
      var params = {}
      params.hobject_pid = node.hobject_pid
      params.anchor_pid = node.anchor_pid
      params.angle_x = node.angle_x
      params.angle_y = node.angle_y
      params.angle_z = node.angle_z
      params.x = node.x + clones.length * 1
      params.y = node.y
      params.z = node.z
      params.scale = node.scale
      // Add space pid to params payload
      params.pid = this.space.pid
      console.log(params)
      await this.$store.dispatch('hoverlay/createNodeInSpace', params)
    },
    confirmExitWithoutSave() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('DeleteNodeTitle'),
        text: this.$t('DeleteNodeDescription'),
        acceptText: this.$t('Remove'),
        accept: this.removeNode,
      })
    },
    confirmRemoveNode(nodePid) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('DeleteNodeTitle'),
        text: this.$t('DeleteNodeDescription'),
        acceptText: this.$t('Remove'),
        accept: this.removeNode,
        parameters: nodePid,
      })
    },
    async removeNode(nodePid) {
      try {
        await this.$store.dispatch('hoverlay/removeNode', nodePid)
        // fetch the latest space content
        var spaceContent = await this.$store.dispatch('hoverlay/getSpaceContent', {
          layer_name: this.layer_name,
          pid: this.space.pid,
        })
        console.log(spaceContent.storylines)
        var sequences = spaceContent.storylines.sequences
        var i = sequences.length

        while (i--) {
          // Main loop going through all sequences
          if (SequencesUtils.removeNodeRecursively(sequences[i], nodePid) == null) {
            sequences.splice(i, 1)
          }
        }
        var space = { pid: this.space.pid, storylines: { sequences: sequences } }
        await this.$store.dispatch('hoverlay/updateSpace', space)

        // cleanup action(s) where hobject is the only target in storylines
        this.$vs.loading.close()

        this.$vs.notify({
          color: 'success',
          title: 'Success',
          text: 'the object has been deleted from the space! ',
        })
      } catch (err) {
        this.$vs.loading.close()
        console.error(err)
      }
    },
    isNodeSelected(node) {
      return this.selectedNodes.find(n => n.pid == node.pid)
    },
    areMulitpleNodesSelected() {
      return this.selectedNodes.length > 1
    },
    toggleSelectedNode(node) {
      if (this.areMulitpleNodesSelected()) {
        this.deselectAllNodes()
      }
      this.toggleNodeSelection(node)
    },
    toggleNodeSelection(node) {
      if (this.selectedNodes.find(n => n.pid == node.pid))
        this.removeNodeFromSelection(node)
      else
        this.addNodeToSelection(node)
    },
    addNodeToSelection(node){
      this.selectedNodes.push(node)
    },
    removeNodeFromSelection(node)  {
      this.selectedNodes = this.selectedNodes.filter(n => n.pid != node.pid)
    },
    deselectAllNodes() {
      this.selectedNodes = []
    },
    updateNodeTransform(command) {
      if (command != null) {
        this.commandStack.do(command)
        // Fire event to update the node transform if the command affects a node or a group of nodes
        switch (command.type) {
          case 'node':
            console.log('!!!!!1updateNodeTransform')
            this.$eventBus.$emit('nodeTransformChanged', command.node)
            break
          case 'node_group':
            console.log('!!!!!2updateNodeTransform')
            command.commands.forEach(c => {
              this.$eventBus.$emit('nodeTransformChanged', c.node)
            })
            break
          default:
            break
        }
      }
    },
    editAnchor(anchor_pid) {
      this.$router.push({
        path: `/edit-anchor/${anchor_pid}`,
      })
    },
    editHobject(hobject_pid) {
      this.$router.push({
        path: `/edit-hobject/${hobject_pid}/${this.space_pid}`,
      })
    },
    getPerformanceScore(totalContentLength) {
      var ratio = totalContentLength / 20000000
      return parseInt(ratio * 10)
    },
    getDownloadColorIndicator(totalContentLength) {
      // console.log('totalContentLength')
      // console.log(totalContentLength)
      if (totalContentLength < 5000000) return 'primary'
      else if (totalContentLength >= 5000000 && totalContentLength < 15000000) return 'warning'
      else return 'danger'
    },
    getData64fromQrCode(data64, _) {
      this.qrCodeData64 = data64
    },
    async onQrCodeDownload() {
      var res = await fetch(this.qrCodeData64)
      var blob = await res.blob()
      var a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'

      var url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = this.space.name
      a.click()
      window.URL.revokeObjectURL(url)
    },
    onQrCodeCopy() {
      this.$vs.notify({
        title: 'Success',
        text: this.$t('QrCodeCopy'),
        color: 'success',
        iconPack: 'feather',
        position: 'bottom-left',
        icon: 'icon-check-circle',
      })
    },
    updatePov(pov) {
      this.pov = pov
    },
    updatePano(pano) {
      this.pano = pano
    },
    createNode(anchorPid) {
      this.$router.push({
        path: `/add-to-existing-anchor/${this.space.pid}/${anchorPid}`,
      })
    },
    goToSequencesPage() {
      this.$router.push({
        path: `/s/${this.space.pid}`,
      })
    },
    create() {
      this.$router.push({
        path: `/add-anchor-to-space/${this.space.pid}`,
      })
      // this.$router.push({
      //   path: `/add-to-existing-space/${this.space.pid}`,
      // })
    },
    async updateSpaceAndNodes() {
      // Do NOT save storylines``
      delete this.space['storylines']
      // Save space to MRS
      this.$eventBus.$emit('onSpaceSaved', this.space)
      try {
        await this.$store.dispatch('hoverlay/updateNodes', this.nodes)
        await this.$store.dispatch('hoverlay/updateSpace', this.space)
        this.$vs.notify({
          text: 'Space updated',
          color: 'success',
          icon: 'check_box',
        })
        // Inform command stack of new save point
        this.commandStack.onSave()
        // console.log('SUCCESS')
      } catch (e) {
        // console.log(e.message)
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'Error',
          text: e.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      } finally {
        this.$vs.loading.close()
      }
    },
    reset() {
      this.init()
    },
    getHobject(hobject_pid) {
      return JSON.parse(JSON.stringify(this.hobjects.filter(hobject => hobject.pid == hobject_pid)[0]))
    },
    getAnchor(anchor_pid) {
      return JSON.parse(JSON.stringify(this.anchors.filter(anchor => anchor.pid == anchor_pid)[0]))
    },
    init() {
      // Set Range
      this.range = this.space.range
      this.name = this.space.name
      // Set present_to & present_from
      this.present_to = new Date(this.space.present_to)
      this.present_from = new Date(this.space.present_from)
      // Set QRcode
      this.qrCodeUrl = `https://hoverlay.io/space/${this.space.pid}`
      // console.log(this.initialPlacement)
    },
    EditSpace() {
      this.$router.push({
        path: `/edit-space-settings/` + this.space.pid,
      })
    },
    EditSequencing() {
      this.$router.push({
        path: `/sequencing/` + this.space.pid,
      })
    },
    showUpgradeMessage() {
      this.$vs.notify({
        title: 'Upgrade',
        text: 'Upgrade to a higher plan to create time-based or interaction triggered sequences',
        color: 'warning',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
      })
    },
    removeDuplicatesNode(nodes) {
      var sortedNodes = nodes.sort((a, b) => b.updated_at - a.updated_at)
      const uniquePids = []
      const unique = sortedNodes.filter(node => {
        const isDuplicate = uniquePids.includes(node.hobject_pid)
        if (!isDuplicate) {
          uniquePids.push(node.hobject_pid)
          return true
        }
      })
      return unique
    },
    removeDuplicates(arr) {
      arr
        .sort(function(a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.updated_at) - new Date(a.updated_at)
        })
        .reverse()

      var unique = arr.reduce(function(acc, curr) {
        if (!acc.includes(curr)) acc.push(curr)
        return acc
      }, [])
      return unique
    },
    getNodeFromHobject(hobject) {
      var h = this.nodes.find(node => node.hobject_pid == hobject.pid)
      if (h) return h
      else return null
    },
    getCloneNodesFromHobject(hobject) {
      var nodes = this.getNodesOrderByLastUpdated(hobject)
      if (nodes.length > 1) nodes.splice(0, 1)
      else nodes = null
      return nodes
    },
    getNodesOrderByLastUpdated(hobject) {
      if (this.selectedAnchor) {
        var nodes = this.nodes.filter(
          node => node.hobject_pid == hobject.pid && this.selectedAnchor.pid == node.anchor_pid
        )
        return nodes
          .sort(function(a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.updated_at) - new Date(a.updated_at)
          })
          .reverse()
      } else return []
    },
    getNodesFromAnchor(anchor) {
      return this.nodes.filter(node => node.anchor_pid == anchor.pid)
    },
    getThumbnailFromAnchorPid(anchor) {
      if (anchor.pid == this.anchorPidFloor) return require(`@/assets/images/hoverlay/floor-anchor.png`)
      else {
        if (anchor && anchor.data) return anchor.data
        else return ''
      }
    },
    setDefaultAnchor() {
      var anchor_space = this.anchors_spaces.find(anchor_space => anchor_space.anchor.pid == this.anchorPidFloor)
      if (anchor_space) this.selectedAnchor = anchor_space.anchor
      else if (this.anchors_spaces.length > 0) this.selectedAnchor = this.anchors_spaces[0].anchor
      else return
    },
    confirmRemoveAnchor(anchor) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Remove objects attached to this anchor?',
        text: 'Attached objects will be removed from this space',
        acceptText: this.$t('Remove'),
        accept: this.RemoveAnchor,
        parameters: anchor,
      })
    },
    async RemoveAnchor(anchor) {
      console.log(anchor)
      await this.$store.dispatch('hoverlay/removeAnchorSpace', { space_pid: this.space.pid, anchor_pid: anchor.pid })
      for (let index = 0; index < this.nodes.length; index++) {
        const node = this.nodes[index]
        // console.log(node.pid)
        if (node.space_pid == this.space.pid && node.anchor_pid == anchor.pid) {
          // console.log(node.pid)
          await this.$store.dispatch('hoverlay/removeNode', node.pid)
        }
      }
      this.setDefaultAnchor()
    },
  },
}
</script>

<style lang="scss">
.qr-code {
  width: 100%;
  // max-height: 150px;
}
.qr-code img {
  max-width: 100%;
  height: auto;
}

.card-overlay {
  background: rgba(0, 0, 0, 0.5);
}
.tab {
  height: 50px;
  border-radius: 5px 5px 0px 0px;
  display: inline-block;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.6);
  overflow: hidden;
  align-self: flex-end;
  position: relative;
  // mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
}
.unselected-tab {
  border-radius: 5px 5px 0px 0px;
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
  height: 45px;
  align-self: flex-end;
}

.unselected-tab > img {
  height: 45px;
}
.add-object-tab {
  border-radius: 5px 5px 0px 0px;
  height: 45px;
  align-self: flex-end;
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
  box-shadow: 0 0px 0px rgb(0 0 0 / 0.6);
}
.center-cropped {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
[class*='close-'] {
  color: #777;
  font: 14px/100% arial, sans-serif;
  position: absolute;
  left: 0px;
  text-decoration: none;
  // text-shadow: 1px 1px 1px #fff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  top: -0px;
  // background-color: rgb(255, 255, 255);
  border-radius: 0% 0% 30% 0;
  width: 24%;
  height: 26%;
}
.close-thik:hover {
  cursor: pointer;
}
.no-select {
  user-select: none;
}
</style>
