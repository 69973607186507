<!-- =========================================================================================
    Description: Parent page to prepare the data for EditSpaceChild.vue
========================================================================================== -->

<template>
  <div>
    <edit-space-child
      ref="editSpaceChild"
      v-if="isSpaceContentLoaded && space && nodes && anchors && hobjects && models && anchorPidFloor"
      v-bind:anchorPidFloor="anchorPidFloor"
      v-bind:space="space"
      v-bind:nodes="nodes"
      v-bind:anchors="anchors"
      v-bind:hobjects="hobjects"
      v-bind:anchors_spaces="anchors_spaces"
    ></edit-space-child>
  </div>
</template>

<script>
import EditSpaceChild from './EditSpaceChild.vue'

export default {
  data() {
    return {
      layer_name: JSON.parse(localStorage.getItem('layerInfo')).name,
      isSpaceContentLoaded: false,
    }
  },
  components: {
    EditSpaceChild,
  },
  computed: {
    space_pid: function() {
      return this.$route.params.pid
    },
    space() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.spaces.find(space => space.pid == this.space_pid)
      } else return null
    },
    nodes() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.nodes.filter(node => node.space_pid == this.space_pid)
      } else return null
    },
    anchors() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.anchors
      } else return null
    },
    hobjects() {
      var h = []
      this.nodes.forEach(node => {
        var tmp = this.$store.state.hoverlay.hobjects.find(hobject => hobject.pid == node.hobject_pid)
        if (tmp) h.push(tmp)
      })
      return h
    },
    models() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.models
      } else return null
    },
    anchors_spaces() {
      if (this.$store.state.hoverlay.anchors_spaces) {
        return this.$store.state.hoverlay.anchors_spaces
      } else return null
    },
    anchorPidFloor: function() {
      if (
        this.$store.state.hoverlay &&
        this.$store.state.hoverlay.anchors.find(anchor => anchor.anchor_type == 'floor')
      )
        return this.$store.state.hoverlay.anchors.find(anchor => anchor.anchor_type == 'floor').pid
      else return false
    },
  },
  mounted() {},
  destroyed() {
    this.isSpaceContentLoaded = false
  },

  async created() {
    this.isSpaceContentLoaded = false
    await this.getSpaceContent()
    this.isSpaceContentLoaded = true
    // Fetch anchors if they do not exist already
    if (this.$store.state.hoverlay.anchors.find(anchor => anchor.anchor_type == 'floor') == undefined)
      this.$store.dispatch('hoverlay/fetchAnchor', 'floor')

    if (this.$store.state.hoverlay.anchors.find(anchor => anchor.anchor_type == 'scene-origin') == undefined)
      this.$store.dispatch('hoverlay/fetchAnchor', 'scene-origin')

    if (this.$store.state.hoverlay.anchors.find(anchor => anchor.anchor_type == 'table-top') == undefined)
      this.$store.dispatch('hoverlay/fetchAnchor', 'table-top')
  },
  methods: {
    async getSpaceContent() {
      this.$vs.loading()
      var params = {
        layer_name: this.layer_name,
        pid: this.$route.params.pid,
      }
      await this.$store.dispatch('hoverlay/getAnchorSpace', params.pid)

      await this.$store.dispatch('hoverlay/getSpaceContent', params)
      this.$vs.loading.close()
    },
    confirmExitWithoutSave(next) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: 'Save changes before closing?',
        text: "Your changes will be lost if you don't save them.",
        acceptText: 'Save',
        cancelText: "Don't save",
        accept: () => this.updateSpaceAndNodes(next),
        cancel: () => this.blockRoute(next),
      })
    },
    updateSpaceAndNodes(next) {
      this.$refs.editSpaceChild.updateSpaceAndNodes()
      next(true)
    },
    blockRoute(next) {
      this.nodes.forEach(node => {
        node.is_dirty = false
      })
      next(true)
    },
  },

  beforeRouteLeave(to, from, next) {
    var areNodesDirties = !this.$refs.editSpaceChild.isSaveButtonDisabled
    if (areNodesDirties) {
      this.confirmExitWithoutSave(next)
    } else {
      next(true)
    }
  },
}
</script>

<style lang="scss"></style>
